import React from "react";
import "./Services.css";
import { LinkContainer } from "react-router-bootstrap";

import { Col, Container, Row } from "react-bootstrap";

function Services() {
  return (
    <div className="services">
      <Row>
        <div className="serviceHead">
          <div className="bgGradient">
            <h1 className="serviceH1">Our Services</h1>
          </div>
        </div>
        <LinkContainer to="/service/digital-marketing">
          <Row className="serviceSection1">
            <h2 className="servicesText">Digital Marketing</h2>
          </Row>
        </LinkContainer>
        <LinkContainer to="/service/content-creation">
          <Row className="serviceSection2">
            <h2 className="servicesText">Content Creations</h2>
          </Row>
        </LinkContainer>
      </Row>
    </div>
  );
}

export default Services;
