import React from "react";
import { Row, Col, Container, Button, Image, Card, Form } from "react-bootstrap";

import contentStrategy from "../images/contentStrategy.png";
import blog from "../images/blog.png";
import websiteCopywriting from "../images/websiteCopywriting.png";
import social from "../images/social.png";
import video from "../images/video.png";
import infoAndVisual from "../images/infoAndVisual.png";
import editing from "../images/editing.png";

function ContentCreation() {
  return (
    <div>
      <div className="contentTop">
        <div className="bgGradientBlack"></div>
      </div>
      <div className="contentCreation">
        <Container>
          <Row>
            <Col md={8}>
              <Row>
                <Col>
                  <div className="serviceCard">
                    <div className="serviceBody">
                      <img src={contentStrategy} alt="seo" className="servicesIcons" />
                      <h3 className="serviceCard__head">Content Strategy</h3>
                      <p className="serviceCard__text">
                        Developing comprehensive strategies to guide content creation efforts based on target audience,
                        brand values, and marketing goals.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="serviceCard">
                    <div className="serviceBody">
                      <img src={blog} alt="seo" className="servicesIcons" />
                      <h3 className="serviceCard__head">Blog and Article Writing</h3>
                      <p className="serviceCard__text">
                        Crafting informative, engaging, and SEO-friendly blog posts and articles to attract and educate
                        audiences.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="serviceCard">
                    <div className="serviceBody">
                      <img src={websiteCopywriting} alt="seo" className="servicesIcons" />
                      <h3 className="serviceCard__head">Website Copywriting</h3>
                      <p className="serviceCard__text">
                        Creating compelling and persuasive website copy that effectively communicates a brand's message
                        and drives conversions.
                      </p>
                    </div>
                  </div>
                </Col>

                <Col>
                  <div className="serviceCard">
                    <div className="serviceBody">
                      <img src={social} alt="seo" className="servicesIcons" />
                      <h3 className="serviceCard__head">Social Media Content Creation</h3>
                      <p className="serviceCard__text">
                        Developing shareable and engaging content for social media platforms to build brand awareness
                        and foster audience engagement.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="serviceCard">
                    <div className="serviceBody">
                      <img src={video} alt="seo" className="servicesIcons" />
                      <h3 className="serviceCard__head">Video Production</h3>
                      <p className="serviceCard__text">
                        Planning, shooting, and editing videos to tell captivating stories, showcase products or
                        services, and engage audiences.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="serviceCard">
                    <div className="serviceBody">
                      <img src={infoAndVisual} alt="seo" className="servicesIcons" />
                      <h3 className="serviceCard__head">Infographics and Visual Content</h3>
                      <p className="serviceCard__text">
                        Designing visually appealing infographics, graphics, and other visual assets to simplify complex
                        information and enhance content engagement.
                      </p>
                    </div>
                  </div>
                </Col>

                <Col>
                  <div className="serviceCard">
                    <div className="serviceBody">
                      <img src={editing} alt="seo" className="servicesIcons" />
                      <h3 className="serviceCard__head">Editing and Proofreading</h3>
                      <p className="serviceCard__text">
                        Ensuring content is error-free, consistent, and adheres to brand guidelines through thorough
                        editing and proofreading processes.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={4}>
              <div className="content-box">
                <p className="contentTag">Service</p>
                <h1 className="contentHeader">Content Creation Services</h1>
                <p className="contentInfoText">
                  At Medusa India, we understand that captivating and persuasive content is the backbone of any
                  successful digital marketing campaign. Our content creation services are designed to engage your
                  audience, establish your brand as an industry leader, and drive meaningful interactions. Our team of
                  skilled writers and creatives will craft compelling blog posts, informative articles,
                  attention-grabbing social media content, and engaging website copy that resonates with your target
                  audience. We take the time to understand your brand's voice, values, and goals to deliver customized
                  content that captures attention and drives action. With our content creation services, you can
                  showcase your expertise, build trust with your audience, and create a lasting impact that sets you
                  apart from the competition. Let Medusa India breathe life into your brand's story through exceptional
                  content that sparks meaningful connections.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default ContentCreation;
