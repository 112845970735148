import React, { useState, useEffect } from "react";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import "./Header.css";
import { useNavigate } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import logo from "../images/logo.png";

function Header() {
  return (
    <div>
      <Navbar fixed="top" collapseOnSelect className="navbarBg" expand="lg">
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>
              <img alt="medusa india" src={logo} className="d-inline-block align-top brandImage" />{" "}
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav navbarScroll className="ms-auto">
              <LinkContainer to="/">
                <Nav.Link>Home</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/about-us">
                <Nav.Link>About Us</Nav.Link>
              </LinkContainer>

              <NavDropdown title="Our Services" id="collasible-nav-dropdown">
                <LinkContainer to="/service/digital-marketing">
                  <NavDropdown.Item>Digital Marketing</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/service/content-creation">
                  <NavDropdown.Item>Content Creation</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
              <LinkContainer to="/contact-us">
                <Nav.Link>Contact Us</Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;
