import React from "react";
import { Row, Col, Container, Button, Image, Card, Form } from "react-bootstrap";

import seo from "../images/seo.png";
import ppc from "../images/payPerClick.png";
import social from "../images/socialMediaMarketing.png";
import content from "../images/contentMarketing.png";
import email from "../images/emailMarketing.png";
import conversionRate from "../images/conversionRate.png";
import online from "../images/online.png";
import analytics from "../images/analytics.png";

function DigitalMarketing() {
  return (
    <div>
      <div className="digitalTop">
        <div className="bgGradientBlack"></div>
      </div>
      <div className="digitalMarketing">
        <Container>
          <Row>
            <Col md={4}>
              <div className="digital-box">
                <p className="digitalTag">Service</p>
                <h1 className="digitalHeader">Digital Marketing Services</h1>
                <p className="digitalInfoText">
                  The Gateway to digital marketing success! In today's dynamic business landscape, a strong online
                  presence is crucial for growth and staying ahead of the competition. Our expert team at Medusa India
                  is here to transform your business by harnessing the power of digital marketing strategies tailored to
                  your unique needs. From search engine optimization (SEO) and pay-per-click (PPC) advertising to
                  engaging social media campaigns and compelling content creation, we have the tools and expertise to
                  maximize your online visibility, drive targeted traffic, and convert leads into loyal customers. Don't
                  wait any longer—let Medusa India unleash the true potential of your business in the digital realm!
                </p>
              </div>
            </Col>

            <Col md={8}>
              <Row>
                <Col>
                  <div className="serviceCard">
                    <div className="serviceBody">
                      <img src={seo} alt="seo" className="servicesIcons" />
                      <h3 className="serviceCard__head">SEO</h3>
                      <p className="serviceCard__text">
                        Optimizing websites to improve organic search engine rankings and increase visibility.
                      </p>
                    </div>
                  </div>
                </Col>

                <Col>
                  <div className="serviceCard">
                    <div className="serviceBody">
                      <img src={ppc} alt="seo" className="servicesIcons" />
                      <h3 className="serviceCard__head">Pay Per Click</h3>
                      <p className="serviceCard__text">
                        Managing targeted advertising campaigns on platforms like Google Ads and social media channels
                        to drive traffic and conversions.
                      </p>
                    </div>
                  </div>
                </Col>

                <Col>
                  <div className="serviceCard">
                    <div className="serviceBody">
                      <img src={social} alt="seo" className="servicesIcons" />
                      <h3 className="serviceCard__head">Social Media Marketing</h3>
                      <p className="serviceCard__text">
                        Developing and executing strategies to build brand awareness, engage audiences, and drive
                        conversions on social media platforms.
                      </p>
                    </div>
                  </div>
                </Col>

                <Col>
                  <div className="serviceCard">
                    <div className="serviceBody">
                      <img src={content} alt="seo" className="servicesIcons" />
                      <h3 className="serviceCard__head">Content Marketing</h3>
                      <p className="serviceCard__text">
                        Creating and distributing valuable, relevant, and consistent content to attract and retain a
                        clearly defined audience.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="serviceCard">
                    <div className="serviceBody">
                      <img src={email} alt="seo" className="servicesIcons" />
                      <h3 className="serviceCard__head">Email Marketing</h3>
                      <p className="serviceCard__text">
                        Developing and implementing email campaigns to nurture leads, build customer loyalty, and drive
                        conversions.
                      </p>
                    </div>
                  </div>
                </Col>

                <Col>
                  <div className="serviceCard">
                    <div className="serviceBody">
                      <img src={conversionRate} alt="seo" className="servicesIcons" />
                      <h3 className="serviceCard__head">Conversion Rate Optimization </h3>
                      <p className="serviceCard__text">
                        Optimizing websites and landing pages to improve conversion rates and maximize the value of
                        website traffic.
                      </p>
                    </div>
                  </div>
                </Col>

                <Col>
                  <div className="serviceCard">
                    <div className="serviceBody">
                      <img src={online} alt="seo" className="servicesIcons" />
                      <h3 className="serviceCard__head">Online Reputation Management</h3>
                      <p className="serviceCard__text">
                        Monitoring and managing a brand's online reputation, addressing negative reviews, and fostering
                        positive sentiment.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="serviceCard">
                    <div className="serviceBody">
                      <img src={analytics} alt="seo" className="servicesIcons" />
                      <h3 className="serviceCard__head">Analytics and Reporting</h3>
                      <p className="serviceCard__text">
                        Utilizing data analytics to track and measure the performance of marketing campaigns, providing
                        insights and making data-driven decisions.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default DigitalMarketing;
