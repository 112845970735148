import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import Header from "./Header";
import Footer from "./Footer";
import { Outlet, useLocation } from "react-router-dom";
import "./Layout.css";
import { useCallback } from "react";
import Particles from "react-particles";
import { loadFull } from "tsparticles";

const Layout = () => {
  const particlesInit = useCallback(async (engine) => {
    console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const location = useLocation();

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div>
      <Header />
      <div>
        <div className="layout">
          <div
            style={{
              minHeight: "100vh",
              paddingTop: "10vh",
            }}
          >
            <div style={{ position: "absolute", zIndex: "0" }}>
              <Particles
                id="tsparticles"
                init={particlesInit}
                loaded={particlesLoaded}
                style={{ position: "relative", top: "0", left: "0", zIndex: "-1" }}
                options={{
                  background: {
                    position: 0,
                  },
                  fpsLimit: 120,
                  fullscreen: {
                    enable: true,
                    zIndex: "-1",
                  },
                  interactivity: {
                    events: {
                      onClick: {
                        enable: false,
                        mode: "push",
                      },
                      onHover: {
                        enable: true,
                        mode: "grab",
                        // parallax: {
                        //   enable: true,
                        //   force: 30,
                        //   smooth: 10,
                        // },
                      },
                      resize: true,
                    },
                    modes: {
                      grab: {
                        distance: 200,
                        line_linked: {
                          opacity: 1,
                        },
                        links: {
                          color: "random",
                          opacity: 0.25,
                        },
                      },
                    },
                  },
                  particles: {
                    color: {
                      value: ["#fbfbf6", "#8B4627"],
                    },
                    links: {
                      color: "random",
                      distance: 100,
                      enable: true,
                      opacity: 0.8,
                      width: 2,
                    },
                    collisions: {
                      enable: true,
                    },
                    move: {
                      direction: "none",
                      enable: true,
                      outModes: {
                        default: "bounce",
                      },
                      random: true,
                      speed: 3,
                      straight: false,
                    },
                    number: {
                      density: {
                        enable: true,
                        area: 1000,
                      },
                      value: 50,
                    },
                    opacity: {
                      value: 0.2,
                    },
                    shape: {
                      type: "circle",
                    },
                    size: {
                      value: { min: 1, max: 3 },
                    },
                  },
                  detectRetina: true,
                }}
              />
            </div>
            <Outlet />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
