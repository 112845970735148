import React, { useState } from "react";
import { Row, Col, Container, Button, Image, Card, Form } from "react-bootstrap";
import "./HomeScreen.css";
import { LinkContainer } from "react-router-bootstrap";
import About from "./About";
import bg from "../images/homeBg6.png";
import Services from "./Services";
import Contact from "./Contact";

function HomeScreen() {
  return (
    <div>
      <div className="home" style={{ paddingBottom: "100px" }}>
        <Container>
          <Row>
            <Col md={6} xs={12}>
              <div className="homeLeftContainer">
                <div>
                  <h1 className="homeH1Top">Engage, Convert,</h1>
                  <h1 className="homeH1Bottom">Succeed.</h1>
                </div>
                <p className="homeText">
                  Welcome to Medusa India, your trusted partner for corporate digital marketing and content creation. We
                  specialize in crafting strategic solutions that elevate your brand, captivate your target audience,
                  and drive business growth. Our experienced team combines creativity and data-driven insights to
                  deliver impactful campaigns across multiple platforms. From comprehensive SEO strategies to
                  captivating website design and compelling content creation, we leverage the latest tools and
                  technologies to maximize your online presence and ensure a strong return on investment. Partner with
                  us to unlock the power of digital marketing, engage your audience, and achieve corporate success.
                </p>
                <LinkContainer to="services">
                  <div>
                    <button className="home-btn1">OUR SERVICES</button>
                  </div>
                </LinkContainer>
                <LinkContainer to="/contact-us">
                  <div>
                    <button className="home-btn2">CONNECT</button>
                  </div>
                </LinkContainer>
              </div>
            </Col>
            <Col md={6} xs={12}>
              <div className="imgContainer">
                <img src={bg} alt="medusa india" className="homeBgImage" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <About />
      </div>
      <div>
        <Services />
      </div>
      <div>{/* <Contact /> */}</div>
    </div>
  );
}

export default HomeScreen;
