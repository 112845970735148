import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./screens/Layout";
import HomeScreen from "./screens/HomeScreen";
import About from "./screens/About";
import Contact from "./screens/Contact";
import Services from "./screens/Services";
import DigitalMarketing from "./screens/DigitalMarketing";
import ContentCreation from "./screens/ContentCreation";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Layout />} exact>
            <Route path="" element={<HomeScreen />} />
            <Route path="about-us" element={<About />} />
            <Route path="services" element={<Services />} />
            <Route path="service/digital-marketing" element={<DigitalMarketing />} />
            <Route path="service/content-creation" element={<ContentCreation />} />
            <Route path="contact-us" element={<Contact />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
