import React, { useEffect, useState } from "react";
import "./About.css";
import { Link, useLocation } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";

function About() {
  return (
    <div className="about">
      <h1 className="about-h1">About Us</h1>
      <div className="bgLight">
        <Container>
          <div className="about-box-light">
            <Row>
              <h3 className="about-h3 darkText">Welcome to Medusa India,</h3>
              <p className="aboutText">
                An industry-leading digital marketing and content creation agency committed to assisting businesses in
                thriving in the online environment. With a team of experienced professionals and a passion for
                delivering results, we provide our clients with innovative and comprehensive digital marketing and
                content creation solutions that are tailored to their specific requirements.
              </p>
            </Row>
            <Row>
              <p className="aboutText">
                We adopt a collaborative and strategic approach to the digital marketing and creation of content. We
                believe that content is the key to effective marketing. By collaborating closely with our clients, we
                acquire a comprehensive comprehension of their brand, target audience, and objectives. Through research,
                originality, and meticulous attention to detail, we create content that not only generates results but
                also strengthens their brand identity. At Medusa India, we believe that digital marketing is about more
                than just implementing strategies; it's also about comprehending the nuances of each business and its
                target audience. We take a collaborative approach, working closely with our clients to develop
                individualised strategies that are aligned with their objectives and produce significant outcomes.
              </p>
            </Row>
          </div>
        </Container>
      </div>
      <div className="bgDark">
        <Container>
          <Row>
            <h2 className="about-h2 lightText">Why Select Us?</h2>
          </Row>

          <Row>
            <Col>
              <div className="about-box-dark">
                <h3 className="about-h3 lightText">EXPERTISE</h3>
                <p className="aboutText lightText">
                  Our team is comprised of digital marketing experts with extensive knowledge of the industry's best
                  practises and emerging trends. We remain ahead of the curve in order to provide our customers with the
                  most recent strategies and techniques. We provide a variety of digital marketing services, such as
                  search engine optimisation (SEO), pay-per-click (PPC) advertising, social media marketing, content
                  marketing, email marketing, conversion rate optimisation (CRO), and online reputation management
                  (ORM), among others. Our comprehensive approach guarantees that every aspect of your online presence
                  is optimised for success.
                </p>
              </div>

              <div className="about-box-dark">
                <h3 className="about-h3 lightText">CUSTOMISED STRATEGIES</h3>
                <p className="aboutText lightText">
                  We acknowledge that each business is unique, with its own objectives and target market. We collaborate
                  closely with our clients to comprehend their unique needs and to develop bespoke strategies to resolve
                  their unique challenges and opportunities.
                </p>
              </div>
              {/* </Col>
            <Col> */}
              <div className="about-box-dark">
                <h3 className="about-h3 lightText">DATA-DRIVEN METHODOLOGY</h3>
                <p className="aboutText lightText">
                  We believe in the significance of data. We employ sophisticated analytics tools to gain valuable
                  insights, monitor performance, and make well-informed decisions. We perpetually refine and optimise
                  our strategies to maximise your return on investment by analysing key metrics. We place a premium on
                  transparent communication with our clients. We provide regular updates, comprehensive reports, and
                  clear insights into the progress of your campaigns. We believe that cooperation and openness are
                  essential for establishing effective partnerships.
                </p>
              </div>
              <div className="about-box-dark">
                <h3 className="about-h3 lightText">CLIENT SUCCESS</h3>
                <p className="aboutText lightText">
                  The success of our clientele is our ultimate objective. Our success is determined by the effect we
                  have on the expansion of your business. We are committed to obtaining measurable outcomes and
                  assisting you in achieving your digital marketing goals. Partner with Medusa India immediately and
                  allow us to facilitate your digital success. Contact us immediately to learn how we can elevate your
                  brand, boost your online visibility, and generate meaningful engagement with your target audience.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bgLight">
        <Container>
          <Row>
            <h2 className="about-h2 darkText">Our Vision</h2>
          </Row>
          <div className="about-box-light">
            <Row>
              <p className="aboutText">
                <span className="bigText darkText">0</span>ur mission at Medusa India is to be the premier digital
                marketing agency that enables businesses to flourish in the online world. We envisage a future in which
                every client we serve accomplishes unparalleled digital success by leveraging our expertise and
                innovative strategies.
              </p>
            </Row>
            <Row>
              <h3 className="about-h3 darkText">Delivering Exceptional Results</h3>
              <p className="aboutText">
                Our goal is to consistently provide our clients with exceptional results. Through our comprehensive
                digital marketing solutions, we aim to assist businesses in enhancing their online visibility,
                generating qualified leads, and fostering long-term growth.
              </p>
              <h3 className="about-h3 darkText">Embracing Innovation</h3>
              <p className="aboutText">
                We strive to be at the vanguard of innovation in the ever-changing digital landscape. To ensure that our
                clients remain ahead of the competition and seize new opportunities, we continuously investigate and
                implement new technologies, trends, and strategies.
              </p>
              <p className="aboutText">
                We value enduring relationships with our customers. Our vision includes promoting trusting,
                collaborative, and mutually beneficial relationships. Our objective is to become a trusted advisor and
                an extension of our clients' marketing departments. We intend to play a defining role in the development
                of the digital marketing industry. By adhering to the utmost levels of professionalism, ethics, and
                quality, we hope to establish industry benchmarks and inspire excellence. Our mission is to enable
                businesses of all sizes and in all industries to realise the maximum potential of digital marketing. We
                endeavour to provide our clients with accessible and scalable solutions that enable them to realise
                their objectives regardless of their resources or level of digital maturity. We are committed to making
                a positive impact not only on the enterprises of our clients, but also on the larger community and
                environment. Our operations and partnerships aspire to promote sustainability, inclusivity, and social
                responsibility. Through our unwavering dedication to excellence, innovation, and client satisfaction, we
                are committed to realising our vision and influencing the digital marketing landscape significantly.
              </p>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default About;
