import React, { useEffect, useState } from "react";
import "./Contact.css";
import contact from "../images/contact.png";
import Contact2 from "../images/Contact2.svg";
import { Col, Container, Row } from "react-bootstrap";

function Contact() {
  const [isMobile, setIsMobile] = useState(false);
  function screen() {
    setIsMobile(window.innerWidth <= 767); // Determine if the screen is mobile width
  }

  useEffect(() => {
    screen();
  }, [window.innerWidth]);

  return (
    <div>
      <div className="contactContainer">
        <div className="top-color">
          {isMobile ? (
            <div className="mobContainer">
              <h1 className="contactHead-h1">Connect </h1>
              <p className="contactHead-p"> to secure your scalability.</p>
            </div>
          ) : null}
        </div>
        <div className="bottom-color">
          {isMobile ? null : (
            <Container>
              <h1 className="contactHead-h1">Connect </h1>
              <p className="contactHead-p"> to secure your scalability.</p>
            </Container>
          )}
        </div>
        <div className="middle-box">
          <div className="contactInfo">
            <h2 className="contactHead-h2">Contact Information</h2>
            <a href="mailto:info.medusaindia@gmail.com" style={{ textDecoration: "none" }}>
              <div className="contactItem">
                <div className="iconContainer">
                  <i className="fa-solid fa-envelope contactIcon" />
                </div>
                <p className="contactTextBottom">Info.medusaindia@gmail.com</p>
              </div>
            </a>
            <a href="tel:+917358040616" style={{ textDecoration: "none" }}>
              <div className="contactItem">
                <div className="iconContainer">
                  <i className="fa-solid fa-phone contactIcon" />
                </div>
                <p className="contactTextBottom">+91 73580 40616 </p>
              </div>
            </a>
            <a
              href="https://www.google.com/maps/place/Narayan+Exotica/@22.5652802,72.9367889,17z/data=!3m1!4b1!4m6!3m5!1s0x395e4e116dffa85f:0x75e6140dba657270!8m2!3d22.5652753!4d72.9393638!16s%2Fg%2F11j8vk5g9c?entry=ttu"
              style={{ textDecoration: "none" }}
              target="_blank"
            >
              <div className="contactItem">
                <div className="iconContainer">
                  <i className="fa-solid fa-location-dot contactIcon" />
                </div>

                <p className="contactTextBottom" style={{ marginBottom: "0" }}>
                  #23, Narayan Exotica,
                </p>
                <p className="contactTextBottom" style={{ marginBottom: "0" }}>
                  Anand - Bakrol road Anand,
                </p>
                <p className="contactTextBottom" style={{ marginBottom: "0" }}>
                  GUJARAT 388001 - India
                </p>
              </div>
            </a>
            <div className="socialContainer">
              <a
                href="https://www.facebook.com/medusaindiaa?mibextid=9R9pXO"
                style={{ textDecoration: "none" }}
                target="_blank"
              >
                <i className="fa-brands fa-square-facebook socialIcon" />
              </a>
              <a
                href="https://instagram.com/medusaindia_?igshid=MzRlODBiNWFlZA=="
                style={{ textDecoration: "none" }}
                target="_blank"
              >
                <i className="fa-brands fa-instagram socialIcon" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
