import React from "react";
import "./Footer.css";
import { Row, Col, Container } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

function Footer() {
  return (
    <div className="footer-section">
      <Container>
        <Row>
          <Col xs={12} md={6} style={{ margin: "5px 0" }}>
            <h5 className="footerHead">Medusa India</h5>
            <LinkContainer to="/about-us">
              <p className="footerText">About Us</p>
            </LinkContainer>
            <a href="mailto:info.medusaindia@gmail.com" style={{ textDecoration: "none" }}>
              <p className="footerText">info.medusaindia@gmail.com</p>
            </a>
            <a href="tel:+917358040616" style={{ textDecoration: "none" }}>
              <p className="footerText">+91 73580 40616</p>
            </a>
            <a
              href="https://www.google.com/maps/place/Narayan+Exotica/@22.5652802,72.9367889,17z/data=!3m1!4b1!4m6!3m5!1s0x395e4e116dffa85f:0x75e6140dba657270!8m2!3d22.5652753!4d72.9393638!16s%2Fg%2F11j8vk5g9c?entry=ttu"
              style={{ textDecoration: "none" }}
              target="_blank"
            >
              <p className="footerText">Anand, Gujarat India</p>
            </a>
            <a
              href="https://www.facebook.com/medusaindiaa?mibextid=9R9pXO"
              style={{ textDecoration: "none" }}
              target="_blank"
            >
              <i className="fa-brands fa-square-facebook footerIcon" />
            </a>
            <a
              href="https://instagram.com/medusaindia_?igshid=MzRlODBiNWFlZA=="
              style={{ textDecoration: "none" }}
              target="_blank"
            >
              <i className="fa-brands fa-instagram footerIcon" />
            </a>
          </Col>
          <Col xs={12} md={6} style={{ margin: "5px 0" }}>
            <h5 className="footerHead">Services</h5>
            <LinkContainer to="service/digital-marketing">
              <p className="footerText">Digial Marketing</p>
            </LinkContainer>
            <LinkContainer to="service/content-creation">
              <p className="footerText">Content Creation</p>
            </LinkContainer>
          </Col>
        </Row>
        <Row className="footerBottom" width="100%">
          <Col xs={12} md={6}>
            <LinkContainer to="/">
              <p className="footerCopyRights">
                <i class="fa-regular fa-copyright"></i> Medusa India
              </p>
            </LinkContainer>
          </Col>
          <Col xs={12} md={6}>
            <a target="_blank" style={{ textDecoration: "none" }} href="https://wa.me/916369769821/">
              <p className="footerCredit">Designed & Developed by: ANSHH PAREKH</p>
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
